import { get, post, getResponseData, getErrorMessage } from '../libs/http';
import { camelizeKeys, decamelizeKeys } from 'humps';

export function markDemoAsViewed(demoId) {
  const data = decamelizeKeys({ user_demo: { demo_id: demoId } });
  return post(`/user_demos`, data)
    .then(getResponseData())
    .then(camelizeKeys)
    .catch(getErrorMessage());
}

export function getDemos() {
  return get(`/demos`)
    .then(getResponseData())
    .then(camelizeKeys)
    .catch(getErrorMessage());
}
