import { getResponseData, getErrorMessage, post } from '../libs/http';
import { camelizeKeys, decamelizeKeys } from 'humps';

export function getServicesQuestions(values, isNextQuestion) {
  const data = decamelizeKeys(values);
  return post(
    `/plan_template_questions/${!isNextQuestion ? 'index' : 'next_question'}`,
    data
  )
    .then(getResponseData())
    .then(camelizeKeys)
    .catch(getErrorMessage());
}

export function getServices(values) {
  const data = decamelizeKeys(values);
  return post(`/services/index`, { data })
    .then(getResponseData())
    .then(camelizeKeys)
    .catch(getErrorMessage());
}

export function getServiceType(values) {
  const data = decamelizeKeys(values?.healthPlan);
  return post(`service_types/index`, { data })
    .then(getResponseData())
    .then(camelizeKeys)
    .catch(getErrorMessage());
}

export function postServiceRequests(id, data) {
  return post(`/referrals/${id}/service_requests`, data)
    .then(getResponseData())
    .then(camelizeKeys())
    .catch(getErrorMessage());
}

export function validateServiceRequests(id, data) {
  return post(`/referrals/${id}/service_requests/validate`, data)
  .then(getResponseData())
  .then(camelizeKeys())
  .catch(getErrorMessage());
}

export function putServiceRequests(data) {
  return post(`/service_requests`, data)
    .then(getResponseData())
    .then(camelizeKeys())
    .catch(getErrorMessage());
}

export function getUOM() {
  return post(`/service_uoms/index`)
    .then(getResponseData())
    .then(camelizeKeys())
    .catch(getErrorMessage());
}
