import { get, post, put, getResponseData, getErrorMessage } from '../libs/http';
import { camelizeKeys, decamelizeKeys } from 'humps';

export function submitNewReferral(referralId) {
  return post(`/referrals/${referralId}/submit`)
    .then(getResponseData())
    .then(camelizeKeys)
    .catch(getErrorMessage());
}

export function getPendingReferrals() {
  return post(`/referrals/index`)
    .then(getResponseData())
    .then(camelizeKeys)
    .catch(getErrorMessage());
}

export function getReferralSources() {
  return post('/referral_sources/index')
    .then(getResponseData())
    .then(camelizeKeys)
    .catch(getErrorMessage());
};

export function getReferralMethods() {
  return post('/referral_methods/index')
    .then(getResponseData())
    .then(camelizeKeys)
    .catch(getErrorMessage());
};

export function getHealthPlanNames() {
  return post('/health_plans/index')
    .then(getResponseData())
    .then(camelizeKeys)
    .catch(getErrorMessage());
};

export function getRoutingPriority() {
  return post('/routing_priorities/index')
    .then(getResponseData())
    .then(camelizeKeys)
    .catch(getErrorMessage());
};

export function getReferral(id) {
  return post(`/referrals/${id}/show`)
    .then(getResponseData())
    .then(camelizeKeys)
    .catch(err => {
      getErrorMessage(err);
      throw err;
    });
};

export function updateReferral(id, values) {
  const data = decamelizeKeys(values);

  return put(`/referrals/${id}`, { data })
    .then(getResponseData())
    .then(camelizeKeys)
    .catch(err => {
      getErrorMessage(err);
      throw err;
    });
};

export function createNewReferral(values) {
  const data = decamelizeKeys(values);

  return post('/referrals', { data })
    .then(getResponseData())
    .then(camelizeKeys)
    .catch(err => {
      getErrorMessage(err);
    });
};


export function verifyReferral(values, ) {
  const data = decamelizeKeys(values);

  return post('/referrals_eligibility', { data })
    .then(getResponseData())
    .then(camelizeKeys)
    .catch(err => {
      getErrorMessage(err);
      throw err;
    });
};


export function addAdditionalReferralSource(id, values) {
  const data = { "referral_additional_info": { ...decamelizeKeys(values) } }
  return post(`/referrals/${id}/additional_information`, { data })
    .then(getResponseData())
    .then(camelizeKeys)
    .catch(err => {
      getErrorMessage(err);
      throw err;
    });
}