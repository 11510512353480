
import { post, getResponseData, getErrorMessage } from '../libs/http';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { sendAnalyticsClick } from '../libs/analytics';

export function searchPhysician(attrs, referralId, carrierGdfid) {
  const values = decamelizeKeys(attrs);
  const data = {
    physicians: {
      ...values
    }, 
    referral_id: referralId,
    carrier_gdfid: carrierGdfid
  };

  sendAnalyticsClick('physician_search');

  return post(`/physicians/index`, { data })
    .then(getResponseData)
    .then(camelizeKeys)
    .catch((err) => {
      throw err
    });
}

export function createPhysician(attr, referralId) {
  const data = decamelizeKeys(attr);

  return post(`/referrals/${referralId}/physicians`,  { data })
    .then(getResponseData())
    .catch(err => {
      getErrorMessage(err);
      throw err;
    });
}