import { getResponseData, getErrorMessage, post } from '../libs/http';
import { camelizeKeys, decamelizeKeys } from 'humps';

export function getFacilities(values, referralId) {
  const attrs =  decamelizeKeys(values);
  const data = attrs ? { facilities: { ...attrs }, referral_id: referralId } : {};
  return post('/discharging_facilities/index', { data })
    .then(getResponseData)
    .then(camelizeKeys)
    .catch((err) => {
      throw err;
    });
};

export function updateMedicalSource(data) {
    return post('/discharging_facilities', data)
      .then(getResponseData())
      .then(camelizeKeys())
      .catch(err => {
        getErrorMessage(err.response);
        throw err;
      });
  };