import axios from "axios";
import path from "ramda/es/path";
import mergeDeepRight from "ramda/es/mergeDeepRight";

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const request = (method, url, payload, customHeaders, baseURL, axiosConfig) => {
  const headers = { ...defaultHeaders, ...customHeaders };
  const options = mergeDeepRight(
    {
      method,
      url,
      headers,
      baseURL,
      data: JSON.stringify(payload),
    },
    payload,
  );
  return axios({...options, ...axiosConfig});
};

export const post = (url, data = {}, headers = {}, baseURL = undefined, axiosConfig = {}) =>
  request("post", url, data, headers, baseURL, axiosConfig);
export const put = (url, data = {}, headers = {}, baseURL = undefined, axiosConfig = {}) =>
  request("put", url, data, headers, baseURL, axiosConfig);
export const get = (url, data = {}, headers = {}, baseURL = undefined, axiosConfig = {}) =>
  request("get", url, data, headers, baseURL, axiosConfig);
export const destroy = (url, data = {}, headers = {}, baseURL = undefined, axiosConfig = {}) =>
  request("delete", url, data, headers, baseURL, axiosConfig);

export const getResponseData = path(["data"]);
export const getResponseHeaders = path(["headers"]);
export const getErrorMessage = (item) => {
  return path(['response', 'data', 'error'], item);
}

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return decodeURIComponent(parts.pop().split(';').shift());
}