import { post, getResponseData, getErrorMessage } from '../libs/http';
import { camelizeKeys } from 'humps';

export function getReferralDocumentTypes() {
  return post('/referral_document_types/index')
    .then(getResponseData())
    .then(camelizeKeys)
    .catch(getErrorMessage());
};

const headers = { 'Content-Type': 'multipart/form-data' }

export function addReferralAttachment(data) {
  return post('/referral_attachments', { data }, headers)
    .then(getResponseData())
    .then(camelizeKeys)
    .catch(getErrorMessage());
};

export function getAttachments(id) {
  const data = { referral_id: id }
  return post('/referral_attachments/index', { data })
    .then(getResponseData)
    .then(camelizeKeys)
    .catch(err => {
      getErrorMessage(err)
      throw err
    })
};

export function deleteAttachment(referralid, fileId) {
  const data = { referral_id: referralid }
  return post(`/referral_attachments/${fileId}`, { data })
    .then(getResponseData)
    .then(camelizeKeys)
    .catch(err => {
      getErrorMessage(err)
      throw err
    })
};
