import "react-app-polyfill/ie11"; /** Support IE11 */
import "react-app-polyfill/stable"; /** Support IE11 */
import { ModalSession } from "../components/ModalSession";
import { getCookie } from "../libs/http";

let logoutTimeout = null;
let isRequesting = false;

const headers = {
  Accept: "application/json",
  "X-CSRF-Token": getCookie("_enhanced_portal_session"),
};

const options = {
  method: "POST",
  credentials: "include",
  headers,
};

export const checkForSessionTimeout = () => {
  if (isRequesting) return;

  isRequesting = true;
  fetch("/api/v1/session_timeouts/remaining_time", options)
    .then((res) => res.json())
    .catch((error) => {
      throw error;
    })
    .then(({ time_left: timeInSeconds }) => {
      logoutTimeout = setTimeout(() => {
        fetch("/api/v1/session_timeouts/remaining_time", options)
          .then((res) => res.json())
          .catch((error) => {
            throw error;
          })
          .then((res) => {
            isRequesting = false;
            if (res.time_left < 70) ModalSession(res.time_left);
            else checkForSessionTimeout();
          });
      }, timeInSeconds * 1000 - 70000);
    });
};
