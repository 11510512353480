export function gtag() {
  dataLayer.push(arguments);
}

export const getRequestFlowPageTitle = (isContinuation, step) =>
  `${isContinuation ? 'COS' : 'New Request'} - ${step}`;

// Errors
export const sendAnalyticsError = (pageTitle, errorMessage) => {
  gtag('event', 'client_error', {
    page_title: pageTitle,
    error_message: errorMessage,
  });
};

export const formatErrorMessageWithCode = (code, message) => 
  code && message ? `${code} - ${message}` : message || code;

// Timing
export const sendAnalyticsTiming = (pageTitle, duration) => {
  gtag('event', 'user_time', {
    page_title: pageTitle,
    engagement_time_msec: duration,
    duration,
  });
}

// Click
export const sendAnalyticsClick = (clickedElement) => {
  gtag('event', 'click', {
    clicked_element: clickedElement,
  });
}