import { getHealthPlanNames } from "../../api";
import { checkForSessionTimeout } from "../../api/session";
import hourGlassImage from '../../images/hourglass.png'

export const ModalSession = (timeLeft) => {
  let breakInterval=false;
  const stringModal = `
  <div class="modal-session">
    <div class="modal-content">
      <div class="modal-message">
        <div class="modal-message__content">
          <img
            class="modal-image"
            src=${hourGlassImage}
            alt="hour glass image"
          />
          <span class="modal-message__title text-center">
          <span>Your session is about to <br/>expire in <span class="orange"><span class="time-left"></span> seconds</span>
          </span></span>
          <span class="modal-message__subtitle text-center"
            >Click ‘Stay Here’ to extend the session for another 30 minutes.</span>
          <div class="modal-message__actions flex--align-left push-half--top">
            <button type="button" class="button button--primary stay-here">
              Stay Here</button
            ><button type="button" class="button button--secondary log-out">Log Out</button>
          </div>
        </div>
      </div>
    </div>
</div>
`;
  const htmlObject = document.createElement("div");
  htmlObject.innerHTML = stringModal;

  const body = document.querySelector("body");
  body.insertBefore(htmlObject, body.firstChild);

  document.querySelector(".stay-here").addEventListener("click", () => {
    sessionStorage.setItem("was_session_refreshed", true);
    breakInterval = true;
    document.querySelector(".modal-session").remove();
    getHealthPlanNames().then(checkForSessionTimeout);
  });

  const logOut = () => {
    document.querySelector(".modal-message__title").innerHTML="<span>Logging out...</span>";
    document.querySelector(".modal-message__subtitle").remove();
    document.querySelector(".modal-message__actions").innerHTML="";
    document.querySelector(".main-sidebar__sign-out input[type='submit']").click()
  }

  document.querySelector(".log-out").addEventListener("click", logOut);

  const timeLeftDOM = document.querySelector(".time-left");
  timeLeftDOM.innerHTML = parseInt(timeLeft)-5;
  const start = new Date();
  
  setInterval(() => {
    const current = new Date();

    const count =current - start;
    const time =  Math.floor((count /  1000)) % 60;
    const remainingTime = timeLeft - time - 10;

    remainingTime>0?
    timeLeftDOM.innerHTML = remainingTime:
    timeLeftDOM.innerHTML="";
    
    if (remainingTime <= 0 && !breakInterval) {
      breakInterval=true;
      logOut();
    }
  }, 100);
};
