import { post, getResponseData, getErrorMessage, getResponseHeaders } from "../libs/http";
import { camelizeKeys, decamelizeKeys  } from "humps";

const headers = { "Content-Type": "multipart/form-data" };

export function getStatuses() {
  return post(`/statuses/index`)
    .then(getResponseData())
    .then(camelizeKeys)
    .catch(getErrorMessage());
}

export function searchRequests(attrs) {
  const data = decamelizeKeys(attrs);
  return post(`case/show`, data)
    .then(response => ({
      headers:getResponseHeaders(response), 
      data:getResponseData(response)}))
    .then((res)=>{
      for(const key in res.data){
        if (res.data.hasOwnProperty(key)) {
          res.data[key].serviceHeaderNumber = key;
        }
      }
      return camelizeKeys(res);
    })
    .catch(getErrorMessage());
}

export function addCaseAttachment(data) {
  return post("/referral_case_attachments", { data }, headers)
    .then(getResponseData())
    .then(camelizeKeys)
    .catch(getErrorMessage());
}

export function viewRequestDetails(attrs) {
  const data = decamelizeKeys(attrs)
  return post(`case_details/show`, data)
    .then(getResponseData())
    .then(camelizeKeys)
    .catch(getErrorMessage());
}

export function showDocument(attrs) {
  const data = decamelizeKeys(attrs);
  return post(`document/show`, data, {}, undefined, {responseType: 'blob'})
    .then(getResponseData())
    .catch(getErrorMessage());
}

export function getEditTypes(attrs) {
  const data = decamelizeKeys(attrs);
  return post(`edit_types/index`, data)
    .then(getResponseData)
    .then(camelizeKeys)
    .catch(getErrorMessage());
}

export function cancelationReasons(data) {
  return post(`cancellation_reasons/index`, data)
    .then(getResponseData)
    .then(camelizeKeys)
    .catch(getErrorMessage());
}

export function withdrawalReasons(data) {
  return post(`withdrawal_reasons/index`, data)
    .then(getResponseData)
    .then(camelizeKeys)
    .catch(getErrorMessage());
}

export function cancelationRequestor() {
  return post(`cancellation_requestors/index`)
    .then(getResponseData)
    .then(camelizeKeys)
    .catch(getErrorMessage());
}

export function getEditReasons(data){
  return post(`edit_reasons/index`, data)
  .then(getResponseData)
  .then(camelizeKeys)
  .catch(getErrorMessage());
}

export function editCase(data){
  return post(`case/update`, data)
  .then(getResponseData)
  .then(camelizeKeys)
  .catch(getErrorMessage());
}