import { post, getResponseData, getErrorMessage } from '../libs/http';
import { camelizeKeys } from 'humps';


export function getContactMethodOptions() {
    return post('/contact_methods/index')
      .then(getResponseData())
      .then(camelizeKeys)
      .catch(err => {
        getErrorMessage(err);
        throw err;
      });
}