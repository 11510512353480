
import { post, getResponseData, getErrorMessage } from '../libs/http';
import { camelizeKeys, decamelizeKeys } from 'humps';



export function searchLocationDP(values, currentUser, memberInfo, npi) {
  let data = {
      providers: {
        npi: npi,
        city: values?.city,
        state: values?.state,
        location_name: values?.locationName,
        email_address: currentUser?.email,
        user_type: currentUser?.userType,
        health_plan: memberInfo?.healthPlan?.internalId,
        portal_provider_type: currentUser?.providerType
      }
    };

  const endpoint = '/providers/index';
  return post(endpoint, { data })
    .then(getResponseData())
    .then((res) => camelizeKeys(res))
    .catch((err) => {
      getErrorMessage(err);
      throw err;
    });
}

export function searchLocation(values, memberInfo) {
  let data;
  const { city, state } = values;
  data = {
    q: { city, state },
    referral_id: memberInfo?.id
  };

  const endpoint = '/user_locations/index'; 
  return post(endpoint, { data })
    .then(getResponseData())
    .then((res) => camelizeKeys(res))
    .catch((err) => {
      getErrorMessage(err);
      throw err;
  });
}

export function createLocation(attr) {
  const data = decamelizeKeys(attr);

  return post('/user_locations',  { data })
    .then(getResponseData())
    .catch(err => {
      getErrorMessage(err);
      throw err;
    });
}

export function getStatelist() {
  return post('/states/index')
    .then(getResponseData())
    .then(res => camelizeKeys(res))
    .catch(getErrorMessage());
}


export function createProvider(referralId, locationId) {
  const data = {
    provider_location: {
      gdf_id: locationId
    }
  }
  return post(`/referrals/${referralId}/provider_locations`, { data })
    .then(getResponseData())
    .then(res => camelizeKeys(res))
    .catch(getErrorMessage());
}

export function getLocationList(attr) {
  const data = decamelizeKeys(attr);

  return post('/provider_locations/index',  { data })
    .then(getResponseData())
    .then((res) => camelizeKeys(res))
    .catch((err) => {
      throw err
    });
}

export function createProviderLocation(data) {
  return post(`/provider_locations`, { data })
    .then(getResponseData())
    .catch((err) => {
      throw err
    });
}