
import { post, getResponseData, getErrorMessage } from '../libs/http';
import { camelizeKeys } from 'humps';

export function getDiagnosis(attrs, referralId, startDate) {
  const { icdCode, description: long_description } = attrs;
  const data = {
    q: {
      icd_code: icdCode,
      long_description
    },
    referral_id: referralId,
    start_date: startDate
  }
  
  return post(`/diagnosis/index`, { data })
    .then(getResponseData())
    .then((res) => camelizeKeys(res))
    .catch((err) => {
      const error = err?.response?.data;
      return error?.status === 422 ? error : getErrorMessage();
    });
}

export function getDiagnosisByReferral(referralId) {
  return post(`/referrals/${referralId}/referral_diagnosis/index`)
    .then(getResponseData())
    .then((res) => camelizeKeys(res))
    .catch(getErrorMessage());
}

export function createDiagnosis(referralId, data) {
  return post(`/referrals/${referralId}/referral_diagnosis`,{data} )
    .then(getResponseData())
    .then((res) => camelizeKeys(res))
    .catch(getErrorMessage());
}

export function validateICD(data) {
  return post('/diagnosis/validate', data)
    .then(getResponseData())
    .then(res => camelizeKeys(res))
    .catch((error) => error?.response ? error.response.data : getErrorMessage());
}