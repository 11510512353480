// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import { checkForSessionTimeout } from "../src/api/session";
import { gtag, sendAnalyticsClick } from "../src/libs/analytics";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// require('dotenv').config()

var alertClose = document.getElementById('js-alert-close');

if (alertClose) {
  alertClose.addEventListener('click', () => {
    document.getElementsByClassName('alert')[0].style.display = 'none';
  });
}

const node = document.getElementById('analitycs');
const {
  gatg_id,
} = JSON.parse(node.getAttribute('data'));

window.dataLayer = window.dataLayer || [];
gtag('js', new Date());
gtag(
  'config',
  gatg_id,
  {
    debug_mode: true,
    send_page_view: true,
  },
);

document.addEventListener("click", (e) => {
  if (window.location.pathname === "/home/index") {
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState === 4 && xhttp.status === 200) {
        sessionStorage.setItem('is_active', true)
        checkForSessionTimeout()
      }
    };

    xhttp.open(
      'POST',
      '/api/v1/health_plans/index',
      true
    );
    const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content;
    xhttp.setRequestHeader('Accept', 'application/json');
    xhttp.setRequestHeader('Content-type', 'application/json');
    xhttp.setRequestHeader('X-CSRF-Token', csrfToken)

    xhttp.send();
  }

  clickEventFunction(e);
  clickEventFunction2(e);
  clickEventFunction3(e);
});



document.addEventListener("DOMContentLoaded", function (event) {
  window.history.pushState({}, null, null);
});


function clickEventFunction(e) {
  if (e.target && e.target.value === "Sign in") {
    sendAnalyticsClick('sign-in');
  }
  if (e.target && e.target.text === "Forgot your password?") {
    sendAnalyticsClick("forgot-password-link");
  }
  if (e.target && e.target.value === "Email me a recovery link") {
    sendAnalyticsClick("Email-me-a-recovery-link-btn");
  }
  if (e.target && e.target.text === "Florida Blue Provider Training") {
    sendAnalyticsClick("Download-Florida-Blue-Provider-Training");
  }
  if (e.target && e.target.text === "NOMNC Training") {
    sendAnalyticsClick("Download-NOMNC-Training");
  }
}

function clickEventFunction2(e) {
  if (e.target && e.target.text === "Florida Blue Provider Training Recording (Hospital)") {
    sendAnalyticsClick("Download-Florida-Blue-Provider-Training-Recording-Hospital");
  }
  if (e.target && e.target.text === "Florida Blue Provider Training Recording (Post Acute Facility)") {
    sendAnalyticsClick("Download-Florida-Blue-Provider-Training-Recording-Post-Acute-Facility");
  }
  if (e.target && e.target.text === "Request an Initial Authorization") {
    sendAnalyticsClick("Download-Request-an-Initial-Authorization");
  }
  if (e.target && e.target.text === "Continuation-of-Services-Interactive-Reference-Guide") {
    sendAnalyticsClick("Download-Continuation-of-Services-Interactive-Reference-Guide");
  }
  if (e.target && e.target.text === "Florida-Blue-Discharge-Reference-Guide") {
    sendAnalyticsClick("Download-Florida-Blue-Discharge-Reference-Guide");
  }
}

function clickEventFunction3(e) {
  if (e.target && e.target.text === "Florida Blue FAQ") {
    sendAnalyticsClick("Download-Florida-Blue-FAQ");
  }
  if (e.target && e.target.text === "Florida Blue Quick Reference Guide") {
    sendAnalyticsClick("Download-Florida-Blue-Quick-Reference-Guide");
  }
  if (e.target && e.target.text === "Referral Lifecycle and Status Interactive Reference Guide") {
    sendAnalyticsClick("Download-Referral-Lifecycle-and-Status-Interactive-Reference-Guide");
  }
  if (e.target && e.target.text === "Warning Code Tools") {
    sendAnalyticsClick("Download-Warning-Code-Tools");
  }
}
